import React from "react";
import application_image from "../../../assets/img/components/process/process.jpg";
import ApplicationProcess from "../../shared/applicationProcess";

export default function Process() {
  return (
    <section id="process">
      <ApplicationProcess
        steps={[
          "Bewerbe dich jetzt direkt über unser Formular", 
          "Auswahl der passenden Talente",
          "Mit uns auf eine 12-Monatige Reise begeben"
        ]}
        title="Bleibe auf dem Laufenden."
        buttonIconColor="yellow"
        buttontext="Jetzt direkt bewerben"
        buttonGridTemplateAreas={`'. grid2 .''. . text'`}
        buttonTextBackgroundColor="blue"
        buttonTextColor="white"
        image={application_image}
        link="https://utum.typeform.com/to/wmvFTyi3"
      />
    </section>
  );
}
