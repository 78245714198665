import React from "react";

import JourneyStep from "./journey-step";

import openingIcon from "../../../assets/img/components/journey/opening-icon.svg";
import impulsesIcon from "../../../assets/img/components/journey/impulses-icon.svg";
import networkingIcon from "../../../assets/img/components/journey/networking-icon.svg";
import closingIcon from "../../../assets/img/components/journey/closing-icon.svg";

import opening from "../../../assets/img/components/journey/opening.jpg";
import impulses from "../../../assets/img/components/journey/impulses.jpg";
import networking from "../../../assets/img/components/journey/networking.jpg";
import closing from "../../../assets/img/components/journey/closing.jpg";

let steps = [
  {
    title: "NextGen Kick-off",
    subTitle: "Eröffnungsveranstaltung",
    description:
      "Offizieller Startschuss der Digitalinitiative NextGen4Bavaria durch eine formelle Eröffnungsveranstaltung im Munich Urban Colab.",
    alt: "staatsministerium für digitales",
    icon: openingIcon,
    overview: opening,
    modal: "opening"
  },
  {
    title: `NextGen Explore`,
    subTitle: "Impulsveranstaltung",
    description:
      "Vermittlung neuer Lösungsansätze zu neuen Ideen und Innovationen im Kontext des eigenen Unternehmens.",
    alt: "unternehmertum",
    icon: impulsesIcon,
    overview: impulses,
    modal: "impulses"
  },
  {
    title: "NextGen Connect",
    subTitle: "Netzwerkveranstaltung",
    description:
      "Möglichkeit zum Wissens- und Erfahrungsaustausch, unter anderem mit den Expertinnen und Experten vor Ort.",
    alt: "staatsministerium für digitales",
    icon: networkingIcon,
    overview: networking,
    modal: "networking"
  },
  {
    title: "NextGen Final",
    subTitle: "Abschlussveranstaltung",
    description:
      "Jeder Jahrgang findet nach zwölf Monaten seinen Abschluss im Munich Urban Colab.",
    alt: "unternehmertum",
    icon: closingIcon,
    overview: closing,
    modal: "closing"
  },
];

export default function Journey() {

  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="infopragh">
      <div className="content journey grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 body-1">
        <div className="journey-title col-12">
          <h3>Deine Reise als Nextgen</h3>
          <p>in 12 Monaten zu Digital- und Innovationsexpertin oder -Experte!</p>
        </div>
        {steps.map((step, index) => (
          <JourneyStep
            index={index}
            title={step.title}
            subTitle={step.subTitle}
            description={step.description}
            icon={step.icon}
            overview={step.overview}
            modal={step.modal}
          />
        ))}
      </div>
    </section>
  );
}