import React from "react";
import DefautLayout from "../layouts/default";
import * as ProgramPage from "../components/screens/program";
import "../assets/sass/index.scss";


export default function Program() {
  return (
    <DefautLayout title="Programm">
      <div id="program">
          <ProgramPage.Header />
          <ProgramPage.Hero />
          <ProgramPage.ChallengesOffering />
          <ProgramPage.Journey />
          <ProgramPage.LocalHeader />
          <ProgramPage.Process />
      </div>
    </DefautLayout>
  );
}
