import React from "react";

export default function ChallengesOffering() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="infopragh">
      <div className="content grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 body-1">
        <div className="col-12 lg:col-6">
          <h3 className="challenge-title">Deine Herausforderungen</h3>
          <ul>
            <li className="challenge">Du möchtest als Unternehmensnachfolgerin oder Unternehmensnachfolger Dein Unternehmen in die digitale Zukunft führen?</li>
            <li className="challenge">Du suchst den Erfahrungsaustausch mit Gleichgesinnten?</li>
            <li className="challenge">Du willst Deine Kompetenzen im Bereich Digitalisierung und Innovation ausbauen?</li>
          </ul>
        </div>
        <div className="col-12 lg:col-6">
          <h3 className="challenge-title">Unser Angebot</h3>
          <ul>
            <li className="offering">Ein 12-monatiges Programm des Bayerischen Staatsministeriums für Digitales für Unternehmensnachfolgerinnen und Unternehmensnachfolger mit Input von Expertinnen und Experten zu relevanten Digitalthemen</li>
            <li className="offering">Ein exklusives Netzwerk und Innovationsökosystem für Dich als Teilnehmerin oder Teilnehmer</li>
            <li className="offering">Regelmäßige Workshops und Veranstaltungen zur Vertiefung Deiner Digitalkompetenzen</li>
          </ul>
        </div>
      </div>
    </section>
  );
}