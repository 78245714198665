import React from "react";
import Rail from "./rail";
import Button from "./button";

export default function ApplicationProcess(props) {
  return (
    <section className="application-process content xs:p-grid-standard lg:ptl-grid-standard">
      <div className="grid grid-gap-0">
        <div className="col-12 lg:col-5">
          <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 pxt-grid-standard ic-40 xl:ic-80">
            <div className="col-12 headline-1">
              <p>{props.title}</p>
            </div>
            <div className="col-12">
              <Rail list={props.steps} />
            </div>
            <div className="local-header--button col-12 pt-20 grid">
              <div className="local-header--button---info">
                <p className="tc-black fs-button m-a">Nächster Jahrgang: Q1|25 - Q1|26</p>
              </div>
              <Button
                      text="Jetzt direkt bewerben"
                      gridTemplateAreas={`'. . text' '. grid2 .'`}
                      textBackgroundColor="black"
                      textColor="white"
                      iconColor="yellow"
                      target="_blank"
                      link="https://utum.typeform.com/to/wmvFTyi3"
                    />
              </div>
          </div>
        </div>
        <div
          title={props.title}
          className="application-process-image col-12 lg:col-7"
          style={{
            ["backgroundImage"]: `url(${props.image})`,
            ["backgroundSize"]: "cover",
          }}
        ></div>
      </div>
    </section>
  );
}
