import React from "react";
import BlueHero from "../../shared/bluehero";
import hero from "../../../assets/img/components/hero/blueHeroProgram.png";

export default function Hero() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="blueHero">
      <BlueHero
        image={hero}
        lineStyle="blue-hero-text-right display-1 p-standard tt-uppercase t-right"
        line={"Das\n erwartet\n dich"}
      />
    </section>
  );
}
