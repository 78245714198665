import React, { Suspense } from "react";
import Modal from "react-modal";

export default function JourneyStep(props) {
    const [openModal, setOpenModal] = React.useState("");

    return (
        <>
            <div className="col-12 lg:col-3" style={{cursor: "pointer"}} key={props.index} onClick={() => setOpenModal(props.modal)}>
                <img
                src={props.icon}
                className="pb-20 img-h-60"
                alt={props.alt}
                />
                <br />
                <p className="fw-semi-bold">{props.title}</p>
                <p className="fw-semi-bold pb-10">{props.subTitle}</p>
                <p className="pb-20">
                    {props.description}{" "}
                    <span className="fw-semi-bold">mehr</span>
                </p>
            </div>

            { openModal === props.modal ?  
                <Modal
                isOpen={openModal === props.modal}
                contentLabel={props.title}
                onRequestClose={() => setOpenModal("")}
                style={{
                    content: {
                        position: "absolute",
                        top: "55%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "70%",
                        height: "fit-content",
                        padding: "0px"
                    }
                }}>
                <div>
                    <img src={props.overview} className="modal"/> 
                </div>
                </Modal> : null 
            } 
            </>
    );
  }