import React from 'react';
import Button from "./button";
import Modal from 'react-modal';

export default function ImageModal(props) {
  const [openModal, setOpenModal] = React.useState("");

  return (
    <div>
        <Button
        text={props.buttonText}
        gridTemplateAreas={`'. grid2 .' '. . text'`}
        textBackgroundColor="blue"
        textColor="white"
        iconColor="yellow"
        onClick={() => {setOpenModal('header')}}
    />
    
    { openModal === 'header' ?  
        <Modal
        isOpen={openModal === 'header'}
        onRequestClose={() => setOpenModal("")}
        contentLabel={props.contentLabel}
        style={{
            content: {
                position: "absolute",
                top: "55%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60%",
                height: "fit-content",
                padding: "0px",
            }
        }}
        >
        <div className='test'>
            <img src={props.src} className="modal"/> 
        </div>
        </Modal> : null
    }
    </div>
);
}