import React from "react";
import ImageModal from "../../shared/image-modal";
import header from "../../../assets/img/components/header/Program.png";
import modal from "../../../assets/img/components/header/Modal.png";

export default function Header() {

  return (
    <section id="header">
      <header className={`header content grid xs:py-standard md:pb-grid-standard`}
    >
      <div className={`col-12 lg:col-6 header-right `}>
        <img
          className={`header-image img-h-500 `}
          src={header}
          alt={""}
        />
      </div>
      <div
        className={`col-12 lg:col-6 header-left xs:p-grid-standard lg:pyl-grid-standard`}
      >
        <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 justify-end height-100 align-center">
          <div className="col-12">
            <p className={`tc-blue display-1`}>Sei dabei.</p>
            <p className={`pt-10 headline-2`}>Und mach Dein Unternehmen fit für die datengetriebene und vernetzte Welt!</p>
          </div>

          <div className="col-12">

          <ImageModal 
                  src={modal}
                  contentLabel="Header"
                  buttonText="AUF EINEN BLICK"
                />
          </div>
        </div>
      </div>
    </header>
      
    </section>
  );
}