import React from "react";
import Button from "../../shared/button";
import SplitText from "../../shared/splitText";

export default function LocalHeaderProgram() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="localHeaderProgram">
      <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 local-header pxt-grid-standard content ic-40 xl:ic-80">
        <div className={`local-header--text col-12 lg:col-7`}>
          <div className={`tc-blue tt-uppercase fw-bolder m-0 display-1`}>
            <SplitText title="Du willst ein NextGen sein?" />
          </div>
        </div>
      </div>
    </section>
  );
}
